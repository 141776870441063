import React from "react";

const Tagline = () => {
  return (
    <>
      <section className="homepage_tagline">
        <div>
          <marquee
            direction="left"
            loop=""
            className="d-flex brand-tagline nimbus"
          >
            <h2 className="d-inline">Beyond Visibility</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Towards Victory</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Beyond Visibility</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Towards Victory</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Beyond Visibility</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Towards Victory</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Beyond Visibility</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Towards Victory</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Beyond Visibility</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Towards Victory</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Beyond Visibility</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Towards Victory</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Beyond Visibility</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Towards Victory</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Beyond Visibility</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Towards Victory</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Beyond Visibility</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Towards Victory</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Beyond Visibility</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Towards Victory</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Beyond Visibility</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Towards Victory</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Beyond Visibility</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Towards Victory</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Beyond Visibility</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Towards Victory</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Beyond Visibility</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Towards Victory</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Beyond Visibility</h2>
            <span className="mx-4">
              <i class="fa-solid fa-star-of-life"></i>
            </span>
            <h2 className="d-inline">Towards Victory</h2>
          </marquee>
        </div>
      </section>
    </>
  );
};

export default Tagline;
