import React from "react";
import 'swiper/css';
import 'swiper/css/pagination';
import { homepage_banners } from "../data";
import HTMLRenderer from "react-html-renderer";

const Banner = () => {

    const { smallBannerImg, mainBannerImg, title, description, button1Text, button1Link, button2Text, button2Link } = homepage_banners;

  return (
    <>
      <div className="homepage_banner">
        <div className="container pt-5">
          <div className="row">
            <div className="col-md-6">
              <h1 className="banner-title nimbus"><HTMLRenderer html={title} /></h1>
            </div>
            <div className="col-md-6 text-left">
            <div className="banner-thumbnail-image row justify-content-center mb-3">
              <img src={smallBannerImg} alt="" />
            </div>
              <p>
                <HTMLRenderer html={description} />
              </p>
            </div>
          </div>
          <div className="banner-btns row my-md-5 my-3">
            <div className="col-xl-5 col-lg-6 col-md-8 d-flex gap-4 flex-wrap justify-content-md-start justify-content-center">
              <div className="text-center">
              <a href={button1Link} className="btn btn-outline-dark banner-btn"><i class="fa-solid fa-globe"></i> {button1Text}</a>
              </div>
              <div className="text-center">
              <a href={button2Link} className="btn btn-outline-dark banner-btn"><i class="fa-solid fa-chart-simple"></i> {button2Text}</a>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-4">
              <hr />
            </div>
          </div>
          <div className="banner-image row justify-content-center">
            <img src={mainBannerImg} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
