import './assets/css/home.css';
import Footer from './Footer';
import Header from './Header';
import Home from './Home';
import './assets/css/global.css';

function App() {
  return (
    <div className="App">
      <Header/>
      <Home/>
      <Footer/>
    </div>
  );
}

export default App;
