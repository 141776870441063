import React from "react";
import "./assets/css/home.css";
import Services from "./home/Services.jsx";
import Banner from "./home/Banner.jsx";
import Moto from "./home/Moto.jsx";
import Blogs from "./home/Blogs.jsx";
import Tagline from "./home/Tagline.jsx";
import WhoWeAre from "./home/WhoWeAre.jsx";

const Home = () => {
  return (
    <div>
        <Banner/>
        <Tagline />
        <WhoWeAre />
        <Moto/>
        <Services/>
        {/* <Blogs /> */}
    </div>
  );
};

export default Home;
