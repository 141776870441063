import React from "react";
import { homepage_whoweare } from "../data";
import HTMLRenderer from "react-html-renderer";

const WhoWeAre = () => {

  const { heading, description, buttonText, buttonLink, image } = homepage_whoweare;

  return (
    <>
      <section className="homepage_whoweare">
        <div className="container">
            <div className="row">
          <div className="col-md-6 mb-md-3 mb-4">
            <div className="who-we-are-section-title-area">
              <h2 className="gradient section-title"><HTMLRenderer html={heading} /></h2>
              <p className="who-we-are-content">
              <HTMLRenderer html={description} />
              </p>
            </div>
            <div className="view-all-btn text-md-start text-center">
              <button className="view-all btn custom-btn"><a href={buttonLink}>{buttonText}</a></button>
            </div>
          </div>
          <div className="col-md-6">
            <img src={image} alt="" className="who-we-are-image d-md-block d-none" />
          </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhoWeAre;
