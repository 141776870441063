import React from "react";
import { homepage_services } from "../data";
import HTMLRenderer from "react-html-renderer";

const Services = () => {
  const { title, content, services, buttonText, buttonLink } = homepage_services;

  return (
    <>
      <section className="homepage_services">
        <div className="container">
          <div className="row">
            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 our-service-content">
              <h2 className="gradient section-title">{title}</h2>
              <p>
                <HTMLRenderer html={content} />
              </p>
              <div className="view-all-btn d-md-block d-none">
                <button className="view-all btn custom-btn"><a href={buttonLink}>{buttonText}</a></button>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 services">
              <div className="row justify-content-center">
                {services.map((service) => (
                  <div
                    className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
                    key={service.id}
                  >
                    <div className="service p-3">
                      <i className={service.icon}></i>
                      <h3 className="my-3">{service.heading}</h3>
                      <p className="service-description">{service.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="view-all-btn d-md-none d-block text-center">
                <button className="view-all btn custom-btn"><a href={buttonLink}>{buttonText}</a></button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
